:root {
  --app-height: 100%;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Karla', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a, a:hover, a:active {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button:disabled {
  opacity: 0.7;
}

.react-toast-notifications__container {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible:focus {
  outline: none;
  box-shadow: 0 0 3pt 2pt #daae9d !important;
}

.page-fade-enter {
  opacity: 0;
}

.page-fade-enter.page-fade-enter-active {
  opacity: 1;
  transition: all 0.3s;
}

.page-fade-exit {
  opacity: 1;
}

.page-fade-exit.page-fade-exit-active {
  opacity: 0;
  transition: all 0.3s;
}

.tooltip {
  position: relative;
}

.tooltip .tooltip__text {
  visibility: hidden;
  width: 120px;
  background-color: rgb(56, 54, 54);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip .tooltip__text::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  background: inherit;
}

.tooltip .tooltip__text--bottom {
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltip__text--bottom::after {
  transform: rotate( 45deg) translateX(-50%);
  top: -1px;
  left: 50%;
}

.tooltip:hover .tooltip__text {
  visibility: visible;
}

.react-reveal {
  will-change: transform, opacity;
}