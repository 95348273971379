.about-page {
    width: 90%;
    overflow-y: auto;
    height: calc(var(--app-height) - 125px);
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
    margin: 0 auto 40px;
    max-width: 1400px;
}

.about-page__heading {
    margin: 0;
}

.about-page__heading--main {
    color: #AF5C3B;
    font-family: 'Merienda One', cursive;
    font-size: 2.8em;
}

.about-page__content {
    display: flex;
    margin-bottom: 30px;
}

.about-page__content:first-of-type {
    flex-shrink: 0;
}

.about-page__content-left {
    width: 30%;
    position: relative;
    margin-right: 20px;
}

.about-page__content-right {
    width: 70%;
}

.about-page__text {
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.about-page__text--restricted {
    max-width: 700px;
}

.about-page__link, .about-page__link:hover, .about-page__link:active {
    color: #AF5C3B;
}

.about-page__list {
    padding-left: 30px;
}

.about-page__list-item {
    padding-left: 10px;
    margin: 5px 0;
    position: relative;
    list-style-type: none;
}

.about-page__list-item:before {
    content: "";
    position: absolute;
    top: 5px;
    left: -16px;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-image: url(../../images/icons/check2.svg);
}

.about-page__services {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.about-page__services-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    text-align: center;
    box-shadow: 0 5px 17px 2px rgba(0, 0, 0, 0.15);
    margin: 20px 20px 0 0;
    padding: 10px 20px;
    border-radius: 4px;
}

.about-page__services-link:hover {
    background: #AF5C3B;
    color: white;
}

.about-page__services-icon {
    margin-bottom: 10px;
    color: #AF5C3B
}

.about-page__services-link:hover .about-page__services-icon {
    color: white;
}

@media only screen and (max-width: 1200px) {
    .about-page {
        padding: 40px;
    }
}

@media only screen and (max-width: 800px) {
    .about-page__content {
        flex-direction: column;
    }

    .about-page__content-left {
        width: calc(100% - 20px);
    }

    .about-page__content-right {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .about-page {
        padding: 40px 20px;
    }

    .about-page__heading--main {
        font-size: 2.3em;
    }

    .about-page__services {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}